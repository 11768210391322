import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import './App.css';
import { Ccds, ChartDoc, Contact, Daccpm, Education, FellowTraining, Home, Icd, Journal, MagnetSummary, Manuals, PepCart, PeptWritten, PerioperativeEP, RecommendText, Reference, StandardInterrogation, Type } from './Pages';

const App = () => {
  return (
    <Router>
      <div>
        <div className="gradient__bg">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/icd" element={<PerioperativeEP />} />
            <Route path="/pept" element={<Icd />} />
            <Route path="/references" element={<Reference />} />
            <Route path="/edu" element={<Education />} />
            <Route path="/references/daccpm-credentials" element={<Daccpm />} />
            <Route path="/references/fellow-training" element={<FellowTraining />} />
            <Route path="/references/written-exam" element={<PeptWritten />} />
            <Route path="/references/chart-documentation" element={<ChartDoc />} />
            <Route path="/references/standard-interrogation" element={<StandardInterrogation />} />
            <Route path="/references/magnet-summary" element={<MagnetSummary />} />
            <Route path="/references/programmer-cart" element={<PepCart />} />
            <Route path="/references/journal-articles" element={<Journal />} />
            <Route path="/references/texts" element={<RecommendText />} />
            <Route path="/references/hrs" element={<Ccds />} />
            <Route path="/references/manuals" element={<Manuals />} />
            <Route path="/references/what-device" element={<Type />} />
            <Route path="/references/manufacturer-contact" element={<Contact />} />

          </Routes>
        </div>
      </div>
    </Router>
  )
}

export default App