import React from 'react';
import './standardInterrogationHeader.css';

const StandardInterrogationHeader = () => {

  return (
    <div className="pept__standardInterrogationHeader section__padding" id="icd">
        <div className="pept__standardInterrogationHeader-content">
            <h1 className="gradient__text">
                Standard Interrogation Document
            </h1>
            <p>
                We try to evaluate ICDs and pacers in a systematic way to reduce errors. Below you will find our general approach to doing so.
            </p>
            <div className="document-item">
                <div className="document-header">
                    <h4>Standard Interrogation Document</h4>
                </div>
                <iframe src="https://pept.org/res/Pacemaker%20ICD%20Preop%20Eval%20Form%20v10%2010%2024%202022.pdf" title="Standard Interrogation" width="100%" height="650px"></iframe>
            </div>
        </div>
    </div>
  )
}

export default StandardInterrogationHeader