import React from 'react';
import { Navbar } from '../../components';
import { ManualHeader } from '../../containers';

const Manual = () => {
  return (
    <div>
        <div className="gradient__bg">
          <Navbar />
          <ManualHeader />
        </div>
    </div>
  )
}

export default Manual