import React from 'react';
import Ellenbogen1 from '../../assets/Cardiac-pacing-ellenbogen.jpg';
import Barold from '../../assets/cardiac-pacemakers-barold.jpg';
import Moses from '../../assets/cardiac-pacing-moses.jpg';
import Ellenbogen2 from '../../assets/clinical-cardiac-pacing-ellenbogen.jpg';
import './recommendTextHeader.css';

const RecommendTextHeader = () => {

  return (
    <div className="pept__RecommendTextHeader section__padding" id="icd">
        <div className="pept__RecommendTextHeader-content">
            <h1 className="gradient__text">
                Recommended Texts
            </h1>
            <p>
                Click on the covers below to be redirected to the amazon listing.
            </p>
        </div>
        <div className="image-container">
                <a href="https://www.amazon.com/Cardiac-Pacing-ICDs-Kenneth-Ellenbogen/dp/1118459512">
                    <img src={Ellenbogen1} alt="Cardiac Pacing and ICDs - Ellenbogen" />
                </a>
                <a href="https://www.amazon.com/Practical-Guide-Cardiac-Pacing/dp/0781788811">
                    <img src={Moses} alt="A Practical Guide to Cardiac Pacing - Moses" />
                </a>
                <a href="https://www.amazon.com/Cardiac-Pacemakers-Resynchronization-Step-Illustrated/dp/1405186364/">
                    <img src={Barold} alt="Cardiac Pacemakers and Resynchronization Step-by-Step - Barold" />
                </a>
                <a href="https://www.amazon.com/Clinical-Cardiac-Defibrillation-Resynchronization-Therapy/dp/1437716164">
                    <img src={Ellenbogen2} alt="Clinical Cardiac Pacing, Defibrillation, and Resynchronization Therapy - Ellenbogen" />
                </a>
            </div>
    </div>
  )
}

export default RecommendTextHeader