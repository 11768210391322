import React from 'react';
import './peptWrittenHeader.css';

const PeptWrittenHeader = () => {

  return (
    <div className="pept__peptWrittenHeader section__padding" id="icd">
        <div className="pept__peptWrittenHeader-content">
            <h1 className="gradient__text">
                Perioperative Electrophysiology Written Exam
            </h1>
            <p>
                The Perioperative EP training Exam below is a mixed exam—it has some short answer questions and some multiple choice and a few fill-in-the blank questions. The objective is to reinforce important concepts. Take the exam with as much time as needed and submit to Scott Streckenbach when done. He will give you the answers for your review.
            </p>
            <div className="document-item">
                <div className="document-header">
                    <h4>Perioperative EP Training Written Exam <a href="https://pept.org/res/Perioperative%20EP%20Training%20Written%20Exam%20Answer%20Key.pdf">(Download Answer Key here)</a></h4>
                </div>
                <iframe src="https://pept.org/res/Perioperative%20EP%20Training%20Written%20Exam.pdf" title="Written Exam" width="100%" height="650px"></iframe>
            </div>
        </div>
    </div>
  )
}

export default PeptWrittenHeader