import React, { useState } from 'react';
import { RiCloseLine, RiMenu3Line } from 'react-icons/ri';
import './navbar.css';

const Menu = () => (
  <>
  <p><a href="/icd">ICD Reprogramming</a></p>
  <p><a href="/pept">Perioperative EP Training</a></p>
  <p><a href="/references">References</a></p>
  <p><a href="/edu">Educational Cases</a></p>
  </>
)

const Navbar = () => {

  const handleButtonClick = () => {
    window.location.href = 'mailto:sstreckenbach@mgh.harvard.edu';
  }

  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className = "pept__navbar">
      <div className = "pept__navbar-links">
        <div className = "pept__navbar-links_logo">
          <h1><a href="/">PEPT</a></h1>
        </div>
        <div className= "pept__navbar-links_container">
          <Menu />
        </div>
      </div>
      <div className="pept__navbar-contact">
        <button type="button" onClick={handleButtonClick}>Contact Me</button>
      </div>
      <div className="pept__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)}/>
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)}/>
        }
        {toggleMenu && (
          <div className="pept__navbar-menu_container scale-up-center">
            <div className="pept__navbar-menu_container-links">
              <Menu />
              <div className="pept__navbar-menu_container-links-contact">
                <button type="button" onClick={handleButtonClick}>Contact Me</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar