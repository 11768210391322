import React from 'react';
import './educationHeader.css';


const EducationHeader = () => {
  return (
    <div className="pept__peptHeader section__padding" id="icd">
        <div className="pept__peptHeader-content">
            <h1 className="gradient__text">
                Educational Cases
            </h1>
            <p>
                Coming Soon!
            </p>
        </div>
    </div>
  )
}

export default EducationHeader