import React from 'react';
import './cards.css';

const Cards = () => {

  const cards = [
    { title: 'MGH DACCPM Credentialing', url: '/references/daccpm-credentials' },
    { title: 'MGH Cardiac Anesthesia Fellow Training Program', url: '/references/fellow-training' },
    { title: 'Perioperative Electrophysiology Written Exam', url: '/references/written-exam' },
    { title: 'Recommended Chart Documentation', url: '/references/chart-documentation' },
    { title: 'Standard Interrogation Document', url: '/references/standard-interrogation' },
    { title: 'Magnet Summary Sheets', url: '/references/magnet-summary' },
    { title: 'Perioperative EP Programmer Cart', url: '/references/programmer-cart' },
    { title: 'Recommended Journal Articles', url: '/references/journal-articles' },
    { title: 'Recommended Texts', url: '/references/texts' },
    { title: 'HRS Cardiac Rhythm Device Therapy Exam for the Physician', url: '/references/hrs' },
    { title: 'What Type of Device does my Patient Have?', url: '/references/what-device' },
    { title: 'Pacemaker Manuals', url: '/references/manuals' },
    { title: 'Manufacturer Contact Info', url: '/references/manufacturer-contact' },
  ];

  return (
    <div className="card-grid">
      {cards.map((card, index) => (
        <a href={card.url} className="card" key={index}>
          <h2>{card.title}</h2>
        </a>
      ))}
    </div>
  )
}

export default Cards