import React, { useState } from 'react';
import YouTube from 'react-youtube';
import './peptHeader.css';

const videos = [
    { id: 'WN8i8JYA020', title: 'Abbott/SJM tachy therapy OFF' },
    { id: 'AnotherVideoID1', title: 'Abbott/SJM tachy therapy OFF and DOO pacing' },
    { id: 'P3ZH1GqLpeM', title: 'Biotronik tachy therapy OFF' },
    { id: '59F-1Tsux9Q', title: 'Biotronik tachy therapy OFF and DOO pacing' },
    { id: 'AlC2iFkxAsE', title: 'Boston Sci tachy therapy OFF' },
    { id: 'Q8W68KY1wZA', title: 'Boston Sci tachy therapy OFF and DOO pacing' },
    { id: 'AnotherVideoID2', title: 'Boston Sci Sub Q ICD therapy off' },
    { id: 'DdQUwxBkxF4', title: 'Medtronic tachy therapy OFF' },
    { id: 'zLNh0NSUDZE', title: 'Medtronic tachy therapy OFF and VOO pacing' },
    
  ]

const PeptHeader = () => {

  const [currentVideo, setCurrentVideo] = useState(videos[0]);

  const handleSelectionChange = (e) => {
    const selectedVideo = videos.find(video => video.id === e.target.value);
    setCurrentVideo(selectedVideo);
  }

  return (
    <div className="pept__peptHeader section__padding" id="icd">
        <div className="pept__peptHeader-content">
            <h1 className="gradient__text">
                Demo Videos for Reprogramming ICDs
            </h1>
            <h2>
                The videos below demonstrate how to turn off ICDs from each of the ICD manufacturers.
            </h2>
            <p>
                Prior to ever turning off an ICD, remember to have an immediately available method to defibrillate the patient in the event of a dysrhythmia. This usually means having external defibrillation pads attached to the patient and connected to a defibrillator and in the hands of an anesthesia caregiver who is facile with the defibrillator. 
            </p>
            <p>
                When selecting a video to watch, the key question to answer first is whether or not you need to convert the patient’s pacer to asynchronous pacing. If you want to convert an Abbott/St Jude or Medtronic ICD to asynchronous pacing, you must first turn off the ICD’s anti-tachy function. Neither device will allow you to convert to DOO or VOO with the ICD active. 
            </p>
            <p>
                Turning off a Biotronik ICD is easy if you do not need to convert the device to DOO or VOO pacing—you simply push one button. If however you need to convert a Biotronik ICD to DOO or VOO pacing, you must turn off the ICD in a more prolonged manner, turning off each zone in the ICD parameters. The two videos will demonstrate this difference.
            </p>
            <p>  
                Boston Scientific ICDs can be turned off in two ways, either turning off the defibrillator only or converting the ICD to the electrocautery safe mode. If the patient will have a DDD or VVI (or any demand mode), you will simply turn the defibrillator off. If you want to have the patient pace in DOO or VOO, you must use the electrocautery safe mode. The videos below will demonstrate these differences.
            </p>
        </div>
        <div className="dropdown-container">
            <label htmlFor="video-select">Device Type: </label>
            <select id="video-select" onChange={handleSelectionChange}>
                {videos.map((video, index) => (
                    <option key={index} value={video.id}>{video.title}</option>
                ))}
            </select>
        </div>

        <div className="video-wrapper">
            <div className="title-wrapper">
            </div>
            <div className="video-content">
                <YouTube videoId={currentVideo.id} />
            </div>
        </div>
    </div>
  )
}

export default PeptHeader