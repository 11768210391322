import React from 'react';
import './fellowTrainingHeader.css';

const FellowTrainingHeader = () => {

  return (
    <div className="pept__fellowTrainingHeader section__padding" id="icd">
        <div className="pept__fellowTrainingHeader-content">
            <h1 className="gradient__text">
                MGH Cardiac Anesthesia Fellow Training Program
            </h1>
            <h2>
                Steps:
            </h2>
            <ol>
                <li>
                Read an EP Text: <a href="https://www.amazon.com/Cardiac-Pacing-ICDs-Kenneth-Ellenbogen/dp/1118459512/ref=sr_1_fkmr0_1?keywords=Cardiac+Pacing+and+ICDs+6th+Edition+by+Ken+Ellenbogen&qid=1686847371&sr=8-1-fkmr0&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc">Cardiac Pacing and ICDs 6th Edition by Ken Ellenbogen</a> or <a href="https://www.amazon.com/Practical-Guide-Cardiac-Pacing/dp/0781788811">A Practical Guide to Cardiac Pacing 6th Edition by HK Moses</a>
                </li>
                <li>
                    Watch the 22 training lectures.
                </li>
                <li>
                    Pass the Perioperative Electrophysiology Written Exam for Fellows.
                </li>
                <li>
                    Observe 10 Interrogations.
                </li>
                <li>
                    Perform 10 supervised Interrogations.
                </li>
                <li>
                Attend a 1-hour programmer training program for each manufacturer (4 sessions).
                </li>
            </ol>
            <h2>
                Overview
            </h2>
            <p>
                All MGH cardiac anesthesia fellows will be expected to complete the Perioperative EP Training Program. We feel that you should have a strong understanding of how to manage patients with pacers and ICDs in the operating room. In addition, we feel that you should be absolute pacer experts in the cardiac operating room where you will be using a temporary pacemaker almost daily. Finally, we feel that you will benefit from learning the cardiac physiology associated with this training.
            </p>
            <p>
                Most of the training program will be done independently at your convenience. All of the information you will need is on the PEPT.org website. All of our Credentialed Cardiac Anesthesiologists (Scott Streckenbach, Heidi Bas, Yvonne Lai, Jerome Crowley as of this writing) will be very happy to help you at any time.
            </p>
            <p>
                Each fellow should read one of the two EP texts. Cardiac Pacing and ICDs is certainly more in depth and recommended for any fellow who is particularly interested in knowing how to manage EP devices well in the perioperative period. A Practical Guide to Cardiac Pacing is also excellent and will certainly take much less time to read. Both books will give you a solid foundation with which to watch the video lectures. There will be copies in the Fellows office; you may also order your own from Amazon.
            </p>
            <p>  
            The lectures may be watched at your convenience. They will take approximately 20 hours to watch so please plan accordingly. At any time that you have a question, please feel free to contact Scott Streckenbach via text or email.
            </p>
            <p>
                The exam and the answers are available on line also so that preparation for this test should be easy. You will take the test after you have finished steps 1 and 2.
            </p>
            <p>
                After successful completion of the exam, you will start observing interrogations. You may observe credentialed cardiac anesthesiologists in the OR or EP techs/fellows in the EP lab. We will create a text chain that will notify you whenever we are doing an interrogation in the OR. Scott Streckenbach will help each of you connect with the EP techs if you would like to observe them as well. Please complete the Observation Log that is available on the website (Training Documents Section).
            </p>
            <p>
                Then you will perform 10 interrogations with supervision by a credentialed cardiac anesthesiologist or an EP tech/fellow. Please complete the Interrogation Log that is available on the website (Training Documents Section).
            </p>
            <p>
                Finally, you will attend a hands-on programmer training program (held April through June) conducted by the manufacturing representatives for each of the 4 companies (Abbott/St Jude, Biotronik, Boston Scientific, and Medtronic).
            </p>
            <p>
                One final note. If you wish to become “Credentialed” at the MGH, you will do exactly the same steps above except that you will need to do 40 observations and perform 40 interrogations. To get “Credentialed” is completely voluntary.
            </p>
            <p>
                For the really motivated fellow, we recommend taking the Heart Rhythm Society Certification Exam for Device Management. More information can be found on the website.
            </p>
            <div className="document-item">
                        <div className="document-header">
                            <h4>MGH Cardiac Anesthesia Fellow Perioperative Electrophysiology Training Program</h4>
                        </div>
                        <iframe src="https://pept.org/res/MGH%20Cardiac%20Anesthesia%20Fellow%20Perioperative%20EP%20Training%20Program.pdf" title="Observation Log" width="100%" height="650px"></iframe>
                    </div>
        </div>
    </div>
  )
}

export default FellowTrainingHeader