import React from 'react';
import './journalHeader.css';

const journalHeader = () => {

  return (
    <div className="pept__journalHeader section__padding" id="icd">
        <div className="pept__journalHeader-content">
            <h1 className="gradient__text">
                Recommended Journal Articles
            </h1>
            <h2>
                Useful Articles for the Beginning Perioperative Electrophysiologist
            </h2>
            <ol>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/21722856/">
                        Crossley GH, Poole JE, Rozner MA et al. The Heart Rhythm Society Expert Consensus Statement on the perioperative management of patients with implantable defibrillators, pacemakers and arrhythmia monitors: Facilities and patient management. Heart Rhythm 2011;8:1114-54
                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/21245737/">
                        ASA Task Force on Perioperative Management of Patients with Cardiac Implantable Electronic Devices: Practice advisory for the perioperative management of patients with cardiac implantable electronic devices: Pacemakers and ICDs. Anesthesiology 2011;114:247-61
                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/26352380/">
                        Rooke GA, Lombaard SA, Van Norman GA, et al. Initial experience of an anesthesiology-based service for perioperative management of pacemakers and implantable cardioverter defibrillators. Anesthesiology 2015;123:1024- 32
                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/28319519/">
                        Ellis MKM, Treggiari MM, Robertson JM, et al. Process improvement initiative for the perioperative management of patients with a cardiovascular implantable electronic device. Anesth Analg 2017;125:58-65
                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/30601218/">
                        Schulman PM, Treggiari MM, Yanez ND, et al. Electromagnetic interference with protocolized electrosurgery dispersive electrode positioning in patients with implantable cardioverter defibrillators. Anesthesiology 2019;130:530- 40
                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/30928289/">
                        Cronin B, Dalia A, Sandoval K, et al. Perioperative interrogation of Biotronik cardiovascular implantable electronic devices: A guide for anesthesiologists. JCVA in press (describes how to modify the magnet response mode for pacers)
                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/29929895/">
                        Cronin B, Birgersdottir-Green U, Essandoh MK. Perioperative interrogation of Boston Scientific cardiovascular implantable electronic devices: A guide for anesthesiologists. JCVA 2019;33:1076-1089
                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/29291968/">
                        Cronin B, Essandoh MK. Perioperative interrogation of St Jude cardiovascular implantable electronic devices: A guide for anesthesiologists. JCVA 2018;32:982-1000
                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/29217253/">
                        Cronin B, Essandoh MK. Update on cardiovascular implantable electronic devices for anesthesiologists. JCVA 2018;32:1871-1884 (update on leadless pacers and sub-Q ICD—reports the 60 second issue and the different position of the magnet)

                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/23757477/">
                        Schulman PM, Rozner MA. Use caution when applying magnets to pacemakers or defibrillators for surgery. Anesth Analg 2013;117:422 (Case report on complications with magnets used to inhibit ICDs and alter pacing mode in pacers)
                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/27665098/">
                        Frieman H, Higgins JV, Ryan JD et al. Predictors of intraoperative electrosurgery-induced implantable cardioverter defibrillator detection. J Interv Card Electrophysiol 2017;48:21-26. (study looking at ICD detection of cautery during non-cardiac surgery)
                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/27943391/">
                        Gifford J, Larimer K, Thomas C et al. ICD-On registry for perioperative management of ICEDs: Most require no change. PACE 2017;40:128-134 (study looking at ICD detection during surgery)
                    </a> 
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/28216202/">
                        Rozner MA, Kahl EA, Schulman PM. Inappropriate implantable cardioverter defibrillator therapy during surgery: An important and preventable complication. JCVA 2017;31:1037-41
                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/22433577/">
                        Healey JS, Merchant RM, Simpson C, et al. Canadian cardiovascular society/Canadian anesthesiologists’ society/Canadian heart rhythm society joint position statement on the perioperative management of patients with implanted pacemakers and defibrillators. Canadian Journal of Cardiology 2012;28:141-51.
                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/23266999/">
                        Izrailtyan I, Schiller RJ, Katz RI et al. Perioperative pacemaker-mediated tachycardia in the patient with a dual chamber implantable cardioverter defibrillator. Anesth Analg 201;116:307-10
                    </a>
                </li>
                <li>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/32032098/">
                        Practice Advisory for the Perioperative Management of Patients with Cardiac Implantable Electronic Devices: Pacemakers and Implantable Cardioverter–Defibrillators 2020. Anesthesiology 2020, 132:225-52.
                    </a>
                </li>
            </ol>
        </div>
    </div>
  )
}

export default journalHeader