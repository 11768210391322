import React from 'react';
import Scott from '../../assets/Scott.jpg';
import './header.css';

const Header = () => {
  return (
    <div className="pept__header section__padding" id="home">
      <div className="pept__header-content">
        <h1 className="gradient__text">
          Perioperative EP Training Program
        </h1>
        <p>Welcome to the Perioperative Electrophysiology Training Program website. ### Short summary of your titles, achievements, etc here to establish credibility ### This website is designed to help anesthesiologists learn how to manage pacemakers and ICDs in the perioperative period. On this website, you will find the Credentialing Process we use at the MGH, the lectures that are required for this credentialing process, and much more. If you have any questions regarding the material on this website, fill out the form below and I will get back to you as soon as possible.</p>
        <p>If you have questions about anything on this site, please feel free to contact me at <a href="mailto:sstreckenbach@mgh.harvard.edu">sstreckenbach@mgh.harvard.edu</a></p>
      </div>
      <div className="pept__header-image">
            <img src = {Scott} alt ="scott"/>
        </div>
    </div>
  )
}

export default Header