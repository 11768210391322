import React from 'react';
import Image2 from '../../assets/icd-magnet-summary.png';
import Image1 from '../../assets/pacemaker-magnet-summary.png';
import './magnetSummaryHeader.css';

const MagnetSummaryHeader = () => {

  return (
    <div className="pept__magnetSummaryHeader section__padding" id="icd">
        <div className="pept__magnetSummaryHeader-content">
            <h1 className="gradient__text">
                Magnet Summary Sheets
            </h1>
            <p>
            Click on the links below to see the magnet summary sheet for Pacemakers or for ICDs. If you have any question, please feel free to contact the <a href="/references/manufacturer-contact">company reps, company tech support</a>, or contact me.
            </p>
            <div className="image-container__magnet">
                <a href="https://pept.org/res/img/pacemaker-magnet-summary.png">
                    <img src={Image1} alt="Icd Magnet Summary" />
                </a>
                <a href="https://pept.org/res/img/icd-magnet-summary.png">
                    <img src={Image2} alt="Pacemaker Magnet Summary" />
                </a>
                
            </div>
        </div>
    </div>
  )
}

export default MagnetSummaryHeader