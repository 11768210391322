import React from 'react';
import './chartDocHeader.css';

const ChartDocHeader = () => {

  return (
    <div className="pept__chartDocHeader section__padding" id="icd">
        <div className="pept__chartDocHeader-content">
            <h1 className="gradient__text">
                Recommended Chart Documentation
            </h1>
            <h2>
                At the MGH we do the following to document all of our interrogations
            </h2>
            <ol>
                <li>
                    Place programmer reports into the patient’s paper record (using hole puncher on cart) or electronically with a thumb drive
                </li>
                <li>
                    Enter a procedure note in the medical record defining what you did to the patient’s device. Ideally you will also enter any pertinent lead and battery
                </li>
                <li>
                    Maintain a logbook with a patient sticker—again recording the following:
                    <ol type="a">
                        <li>
                            The Device type (Pacer, ICD, CRT-P or CRT-D)
                        </li>
                        <li>
                            Manufacturer
                        </li>
                        <li>
                            Base mode and actual mode if mode switched
                        </li>
                        <li>
                            Type of accelerometer if device in DDDR
                        </li>
                        <li>
                            What you did
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    </div>
  )
}

export default ChartDocHeader