import React from 'react';
import './contactHeader.css';


const ContactHeader = () => {
  return (
    <div className="pept__peptHeader section__padding" id="icd">
        <div className="pept__peptHeader-content">
            <h1 className="gradient__text">
                Manufacturer Contact Info
            </h1>
            <p>
            If you have a question about a patient’s ICD or pacemaker, contact the company representative (if you are in the MGH) or the company support team at the numbers below
            </p>
            <h2>
                TECH SUPPORT
            </h2>
            <p>
                Biotronik Tech Support <a href="tel:800-284-6689">800-284-6689</a>
            </p>
            <p>
                Boston Scientific Tech Support <a href="tel:800-227-3422">800-227-3422</a>
            </p>
            <p>
                Medtronic Tech Support (Pacers) <a href="tel:800-505-4636">800-505-4636</a>
            </p>
            <p>
                Medtronic Tech Support (ICDs) <a href="tel:800-723-4636">800-723-4636</a>
            </p>
            <p>
                St Jude/Abbott Tech Support <a href="tel:800-722-3774">800-722-3774</a>
            </p>
            <p>
                Sorin <a href ="tel:877-663-7674">877-663-7674</a>
            </p>
        </div>
    </div>
  )
}

export default ContactHeader