import React from 'react';
import { Cards } from '../';
import './referenceHeader.css';


const referenceHeader = () => {
  return (
    <div className="pept__referenceHeader section__padding" id="icd">
        <div className="pept__referenceHeader-content">
            <h1 className="gradient__text">
                Reference Materials
            </h1>
            <p>
                These are common References that may be useful to your learning process.
            </p>
        </div>
        <div className="pept__reference-cards">
            <Cards />
        </div>
    </div>
  )
}

export default referenceHeader