import React from 'react';
import { Navbar } from '../../components';
import { RecommendTextHeader } from '../../containers';

const RecommendText = () => {
  return (
    <div>
        <div className="gradient__bg">
          <Navbar />
          <RecommendTextHeader />
        </div>
    </div>
  )
}

export default RecommendText