import React from 'react';
import './manualsHeader.css';

const ManualsHeader = () => {

  return (
    <div className="pept__manualsHeader section__padding" id="icd">
        <div className="pept__manualsHeader-content">
            <h1 className="gradient__text">
                Pacemaker Manuals
            </h1>
        </div>
        <div className="document-links">
                <h3>Manuals for different pacemaker brands are available below</h3>
                <div className="documents-grid">
                    <div className="document-item">
                        <div className="document-header">
                            <h4>Medtronic 5392</h4>
                        </div>
                        <iframe src="https://pept.org/res/Medtronic%205392%20Temporary%20Pacemaker%20Manual.pdf" title="Observation Log" width="100%" height="650px"></iframe>
                    </div>
                    <div className="document-item">
                        <div className="document-header">
                            <h4>Interrogation Log</h4>
                        </div>
                        <iframe src="https://pept.org/res/Interrogation%20Log.pdf" title="Interrogation Log" width="100%" height="650px"></iframe>
                    </div>
                    <div className="document-item">
                        <div className="document-header">
                            <h4>Standard Interrogation Document</h4>
                        </div>
                        <iframe src="https://pept.org/res/Observation%20Log.pdf" title="Observation Log" width="100%" height="650px"></iframe>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default ManualsHeader