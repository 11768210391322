import React from 'react';
import { FaPrint } from 'react-icons/fa';
import YouTube from 'react-youtube';
import L1 from '../../assets/Lecture Slides/l1.pdf';
import { default as L10, default as L11, default as L12 } from '../../assets/Lecture Slides/l10-12.pdf';
import L13 from '../../assets/Lecture Slides/l13.pdf';
import L14 from '../../assets/Lecture Slides/l14.pdf';
import L15 from '../../assets/Lecture Slides/l15.pdf';
import L16 from '../../assets/Lecture Slides/l16.pdf';
import L17 from '../../assets/Lecture Slides/l17.pdf';
import L18 from '../../assets/Lecture Slides/l18.pdf';
import L19 from '../../assets/Lecture Slides/l19.pdf';
import { default as L2, default as L3, default as L4 } from '../../assets/Lecture Slides/l2-4.pdf';
import L20 from '../../assets/Lecture Slides/l20.pdf';
import L21 from '../../assets/Lecture Slides/l21.pdf';
import L22 from '../../assets/Lecture Slides/l22.pdf';
import { default as L5, default as L6, default as L7 } from '../../assets/Lecture Slides/l5-7.pdf';
import L8 from '../../assets/Lecture Slides/l8.pdf';
import L9 from '../../assets/Lecture Slides/l9.pdf';
import './icdHeader.css';

const IcdHeader = () => {
  return (
    <div className="pept__icdHeader section__padding" id="icd">
        <div className="pept__icdHeader-content">
            <h1 className="gradient__text">
                Perioperative EP Training Videos
            </h1>
            <p>The lectures recorded below cover many of the principles related to Perioperative Electrophysiology. You should watch each lecture prior to moving to the next step in the credentialing process. The lectures are recorded using Camtasia Software—thus each lecture is essentially a live lecture in which you will hear my voice and watch the slides and videos. It is easy to pause any lecture and return to finish at your convenience. Some of the lectures are regrettably a little long; for that I apologize in advance. Importantly, if you have a question about the lecture, please feel free to contact me in the OR, or by email at <a href="mailto:sstreckenbach@mgh.harvard.edu">sstreckenbach@mgh.harvard.edu</a></p>
            <p>For printable PDF handouts of lecture slides, click <a href={L1}><FaPrint /></a> next to any video.</p>
        </div>
        <div className="pept__videosIcd">
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 1</h2>
                    <a href={L1}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"WN8i8JYA020"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 2</h2>
                    <a href={L2}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"V2qkgrHaei4"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 3</h2>
                    <a href={L3}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"IZ4LA3X6yfg"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 4</h2>
                    <a href={L4}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"IHd1jeuRneE"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 5</h2>
                    <a href={L5}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"IAkM1UNULfs"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 6</h2>
                    <a href={L6}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"N_yT5IoheQk"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 7</h2>
                    <a href={L7}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"udxrkFjuWyc"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 8</h2>
                    <a href={L8}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"-jCwoDWA8tU"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 9</h2>
                    <a href={L9}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"QLlhb-AmFkM"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 10</h2>
                    <a href={L10}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"3p4WIKcj1e4"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 11</h2>
                    <a href={L11}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"YQhWbu3NouQ"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 12</h2>
                    <a href={L12}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"M-6qPKrekrU"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 13</h2>
                    <a href={L13}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"1ei16dZZFgs"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 14</h2>
                    <a href={L14}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"6TSlSLO8ZgQ"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 15</h2>
                    <a href={L15}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"TavcVg1Na-k"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 16</h2>
                    <a href={L16}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"DuTbUnDmTBE"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 17</h2>
                    <a href={L17}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"2c-JRw0Vbhw"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 18</h2>
                    <a href={L18}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"5ddiOeGqtkM"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 19</h2>
                    <a href={L19}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"KiLdL1nLsco"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 20</h2>
                    <a href={L20}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"ON5UgbDbP2U"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 21</h2>
                    <a href={L21}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"7gAWuUPx3H4"} />
                </div>
            </div>
            <div className="video-wrapperIcd">
                <div className="title-wrapperIcd">
                    <h2>Lecture 22</h2>
                    <a href={L22}><FaPrint /></a>
                </div>
                <div className="video-contentIcd">
                    <YouTube videoId={"KBbmQfDkRtc"} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default IcdHeader