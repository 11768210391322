import React from 'react';
import WhatType from '../../assets/what-type.png';
import './typeHeader.css';

const TypeHeader = () => {

  return (
    <div className="pept__typeHeader section__padding" id="icd">
        <div className="pept__typeHeader-content">
            <h1 className="gradient__text">
                What Type of Device does my Patient Have?
            </h1>
            <div className="image-container">
                <img src={WhatType} alt="Icd Magnet Summary" />             
            </div>
        </div>
    </div>
  )
}

export default TypeHeader