import React from 'react';
import Interrogation from '../../assets/Interrogation Form.pdf';
import './daccpmHeader.css';

const DaccpmHeader = () => {

  return (
    <div className="pept__daccpmHeader section__padding" id="icd">
        <div className="pept__daccpmHeader-content">
            <h1 className="gradient__text">
                MGH DACCPM Credentialing Process
            </h1>
            <h2 className="pept__daccpmHeader-subtitle">
            In order to be credentialed to independently interrogate and reprogram a pacemaker or an ICD, an MGH anesthesiologist must complete the following steps in order:
            </h2>
            <ol>
                <li>
                    <a href="/references/texts">Read a basic EP text </a>
                </li>
                <li>
                    Watch the 22 training lectures
                </li>
                <li>
                    Observe 40 interrogations performed by either a member of the EP team or an EP credentialed cardiac anesthesiologist. Each observation should be recorded on the <a href="https://pept.org/res/Observation%20Log.pdf" download>observation form</a>
                </li>
                <li>
                    Perform 40 interrogations with the direct supervision of a member of the EP team or an EP credentialed cardiac anesthesiologist. Each interrogation should be recorded on the <a href="https://pept.org/res/Interrogation%20Log.pdf" download>interrogation form</a>
                </li>
                <li>
                    Attend a one-hour training seminar for Abbott, Biotronik, Boston Scientific, and Medtronic.
                </li>
                <li>
                    Pass the MGH credentialing Exam
                </li>
                <li>
                    Although we highly recommend that you take and hopefully pass the IBHRE, taking this test is not obligatory, given the cost and time required to prepare.
                </li>
            </ol>
        </div>
        <div className="document-links">
                <h3>All of the documents that you need to fulfill the MGH Credentialing Process are included below:</h3>
                <div className="documents-grid">
                    <div className="document-item">
                        <div className="document-header">
                            <h4>Observation Log</h4>
                        </div>
                        <iframe src="https://pept.org/res/Observation%20Log.pdf" title="Observation Log" width="100%" height="650px"></iframe>
                    </div>
                    <div className="document-item">
                        <div className="document-header">
                            <h4>Interrogation Log</h4>
                        </div>
                        <iframe src="https://pept.org/res/Interrogation%20Log.pdf" title="Interrogation Log" width="100%" height="650px"></iframe>
                    </div>
                    <div className="document-item">
                        <div className="document-header">
                            <h4>Standard Interrogation Document</h4>
                        </div>
                        <iframe src={Interrogation} title="Interrogation Form" width="100%" height="650px"></iframe>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default DaccpmHeader