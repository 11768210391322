import React from 'react';
import { Navbar } from '../../components';
import { DaccpmHeader } from '../../containers';

const Daccpm = () => {
  return (
    <div>
        <div className="gradient__bg">
          <Navbar />
          <DaccpmHeader />
        </div>
    </div>
  )
}

export default Daccpm