import React from 'react';
import Cart from '../../assets/Programmer-Cart.jpg';
import './pepCartHeader.css';

const PepCartHeader = () => {

  return (
    <div className="pept__pepCartHeader section__padding" id="icd">
        <div className="pept__pepCartHeader-content">
            <h1 className="gradient__text">
                Perioperative EP Programmer Cart
            </h1>
            <h2>
            Below is a picture of my EP Cart that we use to interrogate and reprogram Pacemakers and ICDs in the OR. Key components of the cart include:
            </h2>
            <ol>
                <li>
                    Programmer from each manufacturer including the Bost Sci Sub Q ICD programmer if possible
                </li>
                <li>
                    Extra printer paper for each manufacturer or a memory stick (if you are doing all electronic record keeping)
                </li>
                <li>
                    A three-hole paper puncher (unless doing electronic record keeping)
                </li>
                <li>
                    EKG electrodes
                </li>
                <li>
                    A magnet
                </li>
                <li>
                    Cleaning wipes
                </li>
            </ol>
            <div className="image-container">
                <img src={Cart} alt="Perioperative EP Cart" />
            </div>
        </div>
    </div>
  )
}

export default PepCartHeader